<template>
  <div>
    <v-form>
      <v-container>
        <p class="mb-2">
          Recrystallizations are commonly done at the end of an organic synthesis to purify the
          product. Consider the steps listed below and arrange them in the correct order for a
          recrystallization procedure.
        </p>

        <drag-to-rank-input
          v-model="inputs.dragToRankAnswers"
          :items="optionsShuffled"
          class="my-5"
          prepend-text="First Step"
          append-text="Final Step"
        />
      </v-container>
    </v-form>
  </div>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default defineComponent({
  name: 'ChemUCI51lbA5_Q3',
  components: {DragToRankInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        dragToRankAnswers: [],
      },
      dragToRankOptions: [
        'Cool the solution slowly at room temperature and then in an ice bath.',
        'Dry the final recrystallized product.',
        'Heat the crude product in just enough solvent to dissolve the full sample.',
        'Filter the solution while still hot to remove any solid impurities.',
        'Isolate the crystals via vacuum filtration.',
        'Weigh the final product in a pre-weighed flask/vial.',
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.dragToRankOptions, this.seed);
    },
  },
});
</script>
